import * as firebase from "firebase";
import moment from "moment";
// import router from "@/router";

import { getIp } from "@/modules/mod_utils.js";
import { getIpInfo } from "@/modules/mod_utils.js";
// import { wetlandPhotosData } from "@/modules/mod_photos.js";
import { version } from "core-js";

const state = {
  user: null,
  contactInfo: {
    email: "",
    requestType: "",
    comments: "",
  },
  signedInUser: {
    id: null,
    email: null,
    app: null,
    dateTime: Date.now(),
    currDate: null,
    currTime: null,
  },
  isMobile: false,
  error: null,
  contacts: [],
  userContacts: [],
  isAuthenticated: false,
  version: "V20220930-1",
  isAdmin: false,
  userStartSession: "",
  userTimeOnPage: "",
  userInfo: {},
  wetlandsPhotos: null,
};

const getters = {
  user(state) {
    return state.user;
  },
  loading(state) {
    return state.loading;
  },
  error(state) {
    return state.error;
  },
  getIsMobile(state) {
    return state.isMobile;
  },
  isAuthenticated(state) {
    return state.user !== null && state.user !== undefined;
  },
  getUserContacts(state) {
    return state.userContacts;
  },
  getSignedInUser(state) {
    return state.signedInUser;
  },
  getVersion(state) {
    return state.version;
  },
  isAdmin(state) {
    return state.isAdmin;
  },
  getUserStartSession(state) {
    return state.userStartSession;
  },
  getWetlandsPhotos: function () {
    return state.wetlandsPhotos;
  },
};

const actions = {
  // async getWetlandsPhotosData(context) {
  //   const wetlandsPhotos = await wetlandPhotosData();

  //   context.commit("UPDATE_WETLANDS_PHOTOS", wetlandsPhotos);
  // },

  getUserContacts({ state, commit }) {
    // console.log('getUserContacts')
    firebase
      .database()
      .ref("ptownAppContacts")
      .once("value", (snapshot) => {
        commit("SET_USER_CONTACTS", snapshot.val());
      });
  },

  addUserContact({ state }, payload) {
    // console.log('STORE: addUserContact:', payload)
    firebase.database().ref("ptownAppContacts").push(payload);
  },

  addUserLogin({ state }, payload) {
    // console.log('STORE: addUserLogin:', payload)
    firebase.database().ref("ptownAppLogins").push(payload);
  },

  addUserSessionDuration({ state }, payload) {
    // console.log("STORE: addUserSessionDuration:", payload);
    firebase.database().ref("ptownAppUserDuration").push(payload);
  },

  async signUserIn({ dispatch, commit }, payload) {
    commit("SET_LOADING", true);
    commit("CLEAR_ERROR");

    let ip = await getIp();
    let version = state.version;

    firebase
      .auth()
      .signInWithEmailAndPassword(payload.email, payload.password)
      .then((user) => {
        // console.log("USER: ", user);
        commit("SET_LOADING", false);
        let d = Date.now();
        let currDate = moment(d).format("YYYY-MM-DD");
        let currTime = moment(d).format("MMMM Do YYYY, h:mm:ss a");
        const signedInUser = {
          id: user.user.uid,
          email: user.user.email,
          app: user.user.i.B,
          dateTime: Date.now(),
          currDate: currDate,
          currTime: currTime,
          ip: ip,
          version: version,
        };
        commit("SET_SIGNED_IN_USER", signedInUser);

        commit("SET_USER", signedInUser);
        commit("SET_IS_AUTHENTICATED", true);

        if (user.user.email == process.env.VUE_APP_ADMIN) {
          commit("SET_IS_ADMIN", true);
        } else {
          commit("SET_IS_ADMIN", false);
        }

        dispatch("addUserLogin", signedInUser);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", error);
        commit("SET_IS_AUTHENTICATED", false);
        commit("SET_IS_ADMIN", false);
      });
  },

  async logIp({ dispatch, commit }, payload) {
    // console.log("Log Ip");
    commit("SET_LOADING", true);
    commit("CLEAR_ERROR");

    let ip = await getIp();
    let ipInfo = await getIpInfo(ip);

    // console.log("IP Address:", ip);
    // console.log("IP Info:", ipInfo);
    // console.log("IP Country:", ipInfo.location.country);
    // console.log("IP Region:", ipInfo.location.region);
    // console.log("IP City:", ipInfo.location.city);
    // console.log("IP Latitude:", ipInfo.location.lat);
    // console.log("IP Longitude:", ipInfo.location.lng);
    // console.log("IP PostalCode:", ipInfo.location.postalCode);
    // console.log("IP TimeZone:", ipInfo.location.timezone);
    // console.log("IP Domain:", ipInfo.as.domain);
    // console.log("IP DomainName:", ipInfo.as.name);

    let country = ipInfo.location.country;
    let region = ipInfo.location.region;
    let city = ipInfo.location.city;
    let latitude = ipInfo.location.lat;
    let longitude = ipInfo.location.lng;
    let postalCode = ipInfo.location.postalCode;
    let timeZone = ipInfo.location.timezone;
    let domain = ipInfo.as.domain;
    let domainName = ipInfo.as.name;

    let d = Date.now();
    let currDate = moment(d).format("YYYY-MM-DD");
    let currTime = moment(d).format("h:mm:ss a");
    let sessionStartTime = moment(d).format("h:mm:ss a");

    const userInfo = {
      dateTime: Date.now(),
      currDate: currDate,
      currTime: currTime,
      ip: ip,
      country: country,
      region: region,
      city: city,
      latitude: latitude,
      longitude: longitude,
      postalCode: postalCode,
      timeZone: timeZone,
      domain: domain,
      domainName: domainName,
      version: "V20211230_1",
    };

    commit("SET_USER_START_SESSION", sessionStartTime);
    commit("SET_USER_INFO", userInfo);

    // console.log("User Info:", userInfo);
    dispatch("addUserLogin", userInfo);

    // firebase
    //   .database()
    //   .ref("ptownAppLogins")
    //   .push(userInfo);

    // this.$store.dispatch("addUserIp", userInfo);
  },

  async logSpadefootQuiz({ commit }, payload) {
    // console.log("Log Ip");
    commit("SET_LOADING", true);
    commit("CLEAR_ERROR");

    let ip = await getIp();
    let ipInfo = await getIpInfo(ip);

    let d = Date.now();
    let currDate = moment(d).format("YYYY-MM-DD");
    let currTime = moment(d).format("MMMM Do YYYY, h:mm:ss a");

    const userInfo = {
      dateTime: Date.now(),
      currDate: currDate,
      currTime: currTime,
      ip: ip,
    };

    // console.log("User Info:", userInfo);

    firebase.database().ref("ptownAppSpadefootQuiz").push(userInfo);

    // this.$store.dispatch("addUserIp", userInfo);
  },

  async logUserTimeOnPage({ dispatch, commit }, payload) {
    let d = Date.now();
    let sessionStartTime = state.userStartSession;
    let currTime = moment(d).format("h:mm:ss a");

    // console.log("getUserStartSession", state.userStartSession);
    // console.log("currTime: ", currTime);
    // console.log("userInfo", state.userInfo);

    // https://stackoverflow.com/questions/18623783/get-the-time-difference-between-two-datetimes
    let sessionDuration = moment
      .utc(
        moment(currTime, "h:mm:ss a").diff(
          moment(sessionStartTime, "h:mm:ss a")
        )
      )
      .format("HH:mm:ss");

    let ip = state.userInfo.ip;
    let currDate = state.userInfo.currDate;
    currTime = state.userInfo.currTime;

    // console.log("Session Duration: ", sessionDuration);

    const userSessionInfo = {
      currDate: currDate,
      currTime: currTime,
      ip: ip,
      sessionDuration: sessionDuration,
    };

    // commit("SET_USER_START_SESSION", "");

    // console.log("User Info:", userInfo);
    dispatch("addUserSessionDuration", userSessionInfo);
  },

  logout({ commit }) {
    firebase.auth().signOut();
    commit("SET_USER", null);
    commit("SET_IS_AUTHENTICATED", false);
    commit("SET_IS_ADMIN", false);
  },

  clearError({ commit }) {
    commit("CLEAR_ERROR");
  },

  setIsMobile({ commit }, isMobile) {
    // console.log('2. setIsMobile', isMobile)
    commit("SET_IS_MOBILE", isMobile);
  },
};

const mutations = {
  SET_USER(state, payload) {
    state.user = payload;
  },
  SET_IS_AUTHENTICATED(state, payload) {
    state.isAuthenticated = payload;
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_ERROR(state, payload) {
    state.error = payload;
  },
  CLEAR_ERROR(state) {
    state.error = null;
  },
  SET_IS_MOBILE(state, isMobile) {
    state.isMobile = isMobile;
  },
  SET_USER_CONTACTS(state, contacts) {
    state.userContacts = contacts;
  },
  SET_SIGNED_IN_USER(state, signedInUser) {
    state.signedInUser = signedInUser;
  },
  SET_IS_ADMIN(state, isAdmin) {
    state.isAdmin = isAdmin;
  },
  SET_USER_START_SESSION(state, userStartSession) {
    state.userStartSession = userStartSession;
  },
  SET_USER_INFO(state, userInfo) {
    state.userInfo = userInfo;
  },
  UPDATE_WETLANDS_PHOTOS(state, wetlandsPhotos) {
    state.wetlandsPhotos = wetlandsPhotos;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
