<template>
  <div class="footer">
    <div>
      <footer class="container">
        <div class="row">
          <div class="col-12 col-md">
            <!-- <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="d-block mb-2"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="14.31" y1="8" x2="20.05" y2="17.94"></line>
              <line x1="9.69" y1="8" x2="21.17" y2="8"></line>
              <line x1="7.38" y1="12" x2="13.12" y2="2.06"></line>
              <line x1="9.69" y1="16" x2="3.95" y2="6.06"></line>
              <line x1="14.31" y1="16" x2="2.83" y2="16"></line>
              <line x1="16.62" y1="12" x2="10.88" y2="21.94"></line>
            </svg> -->
            <small class="d-block mb-3 text-muted"
              >Copyright &copy; 2021-2022 by EasternSpadefoot.com ({{
                getVersion
              }})</small
            >
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Footer",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getVersion", "isAdmin"]),
    userIsAuthenticated() {
      // this.userName = this.$store.getters.user
      return (
        this.$store.getters.user !== null &&
        this.$store.getters.user !== undefined
      );
    },
  },
  methods: {},
  mounted: function () {},
};
</script>

<style scoped>
/* .footer {
    position: fixed;
    font-size: 0.8em;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: grey;
    color: white;
    text-align: center;
  } */
</style>
